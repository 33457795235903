
import React, { Suspense, lazy, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAllVendorsLeadsCall, getVendorsSingleLeadCall, addVendorsSingleLeadCall, updateVendorsSingleLeadCall, deleteVendorsSingleLeadCall } from '../../services/vendorleads';
import { Card, Table, Modal, Button, Form } from 'react-bootstrap';
import { useFormik } from "formik";
import * as Yup from "yup";
import ComingSoon from '../Components/ComingSoon';
import * as Common from "../../General/common";

function VendorSingleLead() {
    const loggedInUser = useSelector((store) => store.UserLoggedIn.loggedInUser);

    const leadTypeMapping = {
        1: 'Lead',
        2: 'Qualified Lead',
        3: 'Customer',
        4: 'Partner'
    };
    const leadPriorityMapping = {
        1: 'High',
        2: 'Medium',
        3: 'Low'
    }

    const optionsLeads = [
        { value: '', label: 'Select an option' },
        { value: 1, label: leadTypeMapping[1] },
        { value: 2, label: leadTypeMapping[2] },
        { value: 3, label: leadTypeMapping[3] },
        { value: 4, label: leadTypeMapping[4] }
    ];
    const optionsPriority = [
        { value: '', label: 'Select an option' },
        { value: 1, label: leadPriorityMapping[1] },
        { value: 2, label: leadPriorityMapping[2] },
        { value: 3, label: leadPriorityMapping[3] }
    ];

    const getLeadTypeLabel = (leadTypeValue) => {
        return leadTypeMapping[leadTypeValue] || 'Unknown';
    };

    const getLeadPriority = (leadTypeValue) => {
        return leadPriorityMapping[leadTypeValue] || 'Unknown';
    };


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [initialValues, setInitialValues] = useState({
        vendor_id: 0,  // Default value, will be replaced by fetched data
        lead_id: 0,
        email: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        lead_type: 0,
        lead_deal: "",
        lead_title: "",
        lead_priority: 0,
        lead_comment: ""
    });

    const Schema = Yup.object().shape({
        email: Yup.string()
            .email("Invalid email format")
            .required("Email is required"),
        first_name: Yup.string()
            .required("First name is required"),
        last_name: Yup.string()
            .required("Last name is required"),
        phone_number: Yup.string()
            .required("Phone number is required"),
        lead_type: Yup.string()
            .oneOf(Object.keys(leadTypeMapping), "Invalid lead type")
            .required("Lead type is required"),
        lead_title: Yup.string()
            .required("Lead title is required"),
        lead_priority: Yup.string()
            .oneOf(Object.keys(leadPriorityMapping), "Invalid lead priority")
            .required("Lead priority is required"),

    });
    const history = useHistory();

    const { leadId } = useParams();
    const [vendorLeadsResponse, setLeadsResponse] = useState([]);
    const [gettingVendorLeads, setGettingVendorLeads] = useState(false);


    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            updateVendorLead(values);
            setSubmitting(false);
        },
    });


    /// Updating Lead 
    const [updatingLead, setUpdatingLead] = useState(false);

    const [deletingLead, setDeletingLead] = useState(false);

    const updateVendorLead = async (values) => {
        setUpdatingLead(true);
        try {
            const { data } = await updateVendorsSingleLeadCall(values);
            if (data.success) {
                // success scenario
                Common.showSuccessAlertAutoClose(
                    data.message,
                    "success",
                    "Success!",
                    3000
                );
                formik.resetForm();
                handleClose();
                getVendorLeads();
            } else {
                Common.showSuccessAlertAutoClose(
                    data.message,
                    "warning",
                    "Failed!",
                    3000
                );
            }
        } catch (err) {
            Common.showSuccessAlertAutoClose(
                "Some error occured while processing your request!",
                "warning",
                "Failed!",
                3000
            );
        } finally {
            setUpdatingLead(false);
        }
    };

    const getVendorLeads = async () => {
        setGettingVendorLeads(true);
        try {
            const values = {
                vendor_id: loggedInUser.vendor_id,
                lead_id: leadId
            };
            const { data } = await getVendorsSingleLeadCall(values);
            if (data.success) {
                setLeadsResponse(data.data);
                setInitialValues({
                    lead_id: data.data.lead_id,
                    vendor_id: data.data.vendor_id,
                    email: data.data.email,
                    first_name: data.data.first_name,
                    last_name: data.data.last_name,
                    phone_number: data.data.phone_number,
                    lead_type: data.data.lead_type,
                    lead_deal: data.data.lead_deal,
                    lead_title: data.data.lead_title,
                    lead_priority: data.data.lead_priority,
                    lead_comment: data.data.lead_comments[0].lead_comment
                });
            } else {
                setLeadsResponse([])
            }
        } catch {
            setLeadsResponse([])
        } finally {
            setGettingVendorLeads(false);
        }
    };

    const deleteVendorLead = async () => {
        setDeletingLead(true);
        try {
            const values = {
                vendor_id: loggedInUser.vendor_id,
                lead_id: leadId
            };
            const { data } = await deleteVendorsSingleLeadCall(values);
            if (data.success) {
                history.push(
                    `/vendor-leads`
                );
            } else {
                setLeadsResponse([])
            }
        } catch {
            setLeadsResponse([])
        } finally {
            setDeletingLead(false);
        }
    };


    useEffect(() => {
        getVendorLeads();
    }, []);
    return (
        <>
            <div className='d-flex mb-6 justify-content-end'>
                <div className='d-flex align-items-center me-2'>
                    <div role={"button"} onClick={handleShow}
                        className="text-uppercase border-0 btn-primary-product-detail"
                        style={{ backgroundColor: "#EFD7CE", color: '#000000', width: '220px', textAlign: 'center', marginTop: '20px', marginRight: '20px' }}
                    >
                        DELETE LEAD
                    </div>
                </div>
                <div className='d-flex align-items-center'>
                    <div role={"button"}
                       onClick={() => history.push(`/vendor-lead-activity/${leadId}`)}
                        className="text-uppercase border-0 btn-primary-product-detail"
                        style={{ backgroundColor: "#EFD7CE", color: '#000000', width: '220px', textAlign: 'center', marginTop: '20px' }}
                    >
                        LEAD ACTIVITY
                    </div>
                </div>
            </div>


            <Modal
                show={show}
                onHide={() => {
                    formik.resetForm();
                    handleClose();
                }}
                backdrop="static"
                keyboard={false}
                centered
                size="lg"
            >
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title className='add-new-tranding-topic-title'>Are you sure want to remove this Lead ?</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className='row'>

                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0 py-2'>
                    <Button
                        variant="dark"
                        className={`${(formik.isSubmitting || deletingLead) ? 'pr-9' : ''} upload-btn`}
                        onClick={() => {
                            deleteVendorLead()
                        }}
                    >
                        Yes
                        {
                            (formik.isSubmitting || deletingLead) && <span className="ml-3 spinner spinner-white"></span>
                        }
                    </Button>
                    <Button
                        variant="dark"
                        className={`${(formik.isSubmitting || deletingLead) ? 'pr-9' : ''} upload-btn`}

                        onClick={() => {
                            handleClose()
                        }}
                    >
                        No 
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className='row'>
                <div className="col-md-6">
                    <Form.Group className="mb-8" controlId="formbasictitle">
                        <Form.Label className='modal-form-label'>First Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="first_name"
                            {...formik.getFieldProps("first_name")}
                            className="input-shadow"
                        />
                        {formik.touched.first_name && formik.errors.first_name ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.first_name}</div>
                            </div>
                        ) : null}
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group className="mb-8" controlId="formbasictitle">
                        <Form.Label className='modal-form-label'>Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="last_name"
                            {...formik.getFieldProps("last_name")}
                            className="input-shadow"
                        />
                        {formik.touched.last_name && formik.errors.last_name ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.last_name}</div>
                            </div>
                        ) : null}
                    </Form.Group>
                </div>

                <div className="col-md-6">
                    <Form.Group className="mb-8" controlId="formbasictitle">
                        <Form.Label className='modal-form-label'>Email</Form.Label>
                        <Form.Control
                            type="text"
                            name="email"
                            {...formik.getFieldProps("email")}
                            className="input-shadow"
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.email}</div>
                            </div>
                        ) : null}
                    </Form.Group>
                </div>

                <div className="col-md-6">
                    <Form.Group className="mb-8" controlId="formbasictitle">
                        <Form.Label className='modal-form-label'>Phone Number</Form.Label>
                        <Form.Control
                            type="text"
                            name="phone_number"
                            {...formik.getFieldProps("phone_number")}
                            className="input-shadow"
                        />
                        {formik.touched.phone_number && formik.errors.phone_number ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.phone_number}</div>
                            </div>
                        ) : null}
                    </Form.Group>
                </div>

                <div className="col-md-6">
                    <Form.Group className="mb-8" controlId="formbasictitle">
                        <Form.Label className='modal-form-label'>Type</Form.Label>
                        <Form.Control
                            as="select"
                            name="lead_type"
                            {...formik.getFieldProps("lead_type")}
                            className="input-shadow"
                        >
                            {optionsLeads.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Form.Control>
                        {formik.touched.lead_type && formik.errors.lead_type ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.lead_type}</div>
                            </div>
                        ) : null}
                    </Form.Group>
                </div>

                <div className="col-md-6">
                    <Form.Group className="mb-8" controlId="formbasictitle">
                        <Form.Label className='modal-form-label'>Priority</Form.Label>
                        <Form.Control
                            as="select"
                            name="lead_priority"
                            {...formik.getFieldProps("lead_priority")}
                            className="input-shadow"
                        >
                            {optionsPriority.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Form.Control>
                        {formik.touched.lead_priority && formik.errors.lead_priority ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.lead_priority}</div>
                            </div>
                        ) : null}
                    </Form.Group>
                </div>

                <div className="col-md-12">
                    <Form.Group className="mb-8" controlId="formbasictitle">
                        <Form.Label className='modal-form-label'>Title</Form.Label>
                        <Form.Control
                            type="text"
                            name="lead_title"
                            {...formik.getFieldProps("lead_title")}
                            className="input-shadow"
                        />
                        {formik.touched.lead_title && formik.errors.lead_title ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.lead_title}</div>
                            </div>
                        ) : null}
                    </Form.Group>
                </div>

                <div className="col-md-12">
                    <Form.Group className="mb-8" controlId="formbasictitle">
                        <Form.Label className='modal-form-label'>Deal</Form.Label>
                        <Form.Control
                            type="text"
                            as="textarea"
                            name="lead_deal"
                            {...formik.getFieldProps("lead_deal")}
                            className="input-shadow"
                            maxLength={500}
                        />
                        {formik.touched.lead_deal && formik.errors.lead_deal ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.lead_deal}</div>
                            </div>
                        ) : null}
                    </Form.Group>
                </div>

                <div className="col-md-12">
                    <Form.Group className="mb-8" controlId="formbasictitle">
                        <Form.Label className='modal-form-label'>Comment</Form.Label>
                        <Form.Control
                            type="text"
                            as="textarea"
                            name="lead_comment"
                            {...formik.getFieldProps("lead_comment")}
                            className="input-shadow"
                            maxLength={500}
                        />
                        {formik.touched.lead_comment && formik.errors.lead_comment ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.lead_comment}</div>
                            </div>
                        ) : null}
                    </Form.Group>
                </div>

                <div className='mb-6'>
                    <div className='d-flex justify-content-end align-items-center'>
                        {/* Other elements here */}
                        <Button
                            variant="dark"
                            className={`${(formik.isSubmitting || updatingLead) ? 'pr-9' : ''} upload-btn`}
                            disabled={formik.isSubmitting || updatingLead}
                            onClick={() => {
                                formik.handleSubmit();
                            }}
                        >
                            Update Lead
                            {
                                (formik.isSubmitting || updatingLead) && <span className="ml-3 spinner spinner-white"></span>
                            }
                        </Button>
                    </div>
                </div>


            </div>
        </>
    );


}


export default VendorSingleLead;