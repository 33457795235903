
import React, { Suspense, lazy, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAllVendorsLeadsCall, getVendorsSingleLeadCall, addVendorsSingleLeadCall, updateVendorsSingleLeadCall, deleteVendorsSingleLeadCall, getVendorsSingleLeadActivityCall } from '../../services/vendorleads';
import { Card, Table, Modal, Button, Form } from 'react-bootstrap';
import { useFormik } from "formik";
import * as Yup from "yup";
import ComingSoon from '../Components/ComingSoon';
import * as Common from "../../General/common";

function VendorActivityLead() {
    const loggedInUser = useSelector((store) => store.UserLoggedIn.loggedInUser);

    const leadTypeMapping = {
        1: 'Lead',
        2: 'Qualified Lead',
        3: 'Customer',
        4: 'Partner'
    };
    const leadPriorityMapping = {
        1: 'High',
        2: 'Medium',
        3: 'Low'
    };

    const optionsLeads = [
        { value: '', label: 'Select an option' },
        { value: 1, label: leadTypeMapping[1] },
        { value: 2, label: leadTypeMapping[2] },
        { value: 3, label: leadTypeMapping[3] },
        { value: 4, label: leadTypeMapping[4] }
    ];
    const optionsPriority = [
        { value: '', label: 'Select an option' },
        { value: 1, label: leadPriorityMapping[1] },
        { value: 2, label: leadPriorityMapping[2] },
        { value: 3, label: leadPriorityMapping[3] }
    ];

    const getLeadTypeLabel = (leadTypeValue) => {
        return leadTypeMapping[leadTypeValue] || 'Unknown';
    };

    const getLeadPriority = (leadTypeValue) => {
        return leadPriorityMapping[leadTypeValue] || 'Unknown';
    };

    const history = useHistory();
    const { leadId } = useParams();
    const [filter, setFilter] = useState('');
    const [vendorLeadsResponse, setLeadsResponse] = useState([]);
    const [gettingVendorLeads, setGettingVendorLeads] = useState(false);

    const getVendorActivityLeads = async () => {
        setGettingVendorLeads(true);
        try {
            const values = {
                vendor_id: loggedInUser.vendor_id,
                lead_id: Number(leadId)
            };
            const { data } = await getVendorsSingleLeadActivityCall(values);
            if (data.success) {
                // Sort by created_at in descending order
                const sortedData = data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setLeadsResponse(sortedData);
            } else {
                setLeadsResponse([]);
            }
        } catch {
            setLeadsResponse([]);
        } finally {
            setGettingVendorLeads(false);
        }
    };

    useEffect(() => {
        getVendorActivityLeads();
    }, []);

    // Handle filtering based on the search input
    const filteredLeads = vendorLeadsResponse.filter((activity) =>
        activity.activity_details.toLowerCase().includes(filter.toLowerCase())
    );

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    return (
        <>
            <div className='toptranding-small-title'>LEAD ACTIVITY</div>
            <div className='dashboard-card-wrapper topic-tranding-table'>
                <Table className='mb-0' responsive>
                    <thead>
                        <tr className='bg-primary'>

                            <th>Activity Type</th>
                            <th>Activity Details</th>
                            <th>Created At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredLeads.map((activity, index) => (
                            <tr key={index} className='border-bottom'>

                                <td>{activity.activity_type}</td>
                                <td>{activity.activity_details}</td>
                                <td>{formatDate(activity.created_at)}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </>
    );
}

export default VendorActivityLead;