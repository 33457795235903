import * as API from "../General/api";

// vendors leads calls starts


export function getAllVendorsLeadsCall(values) {
    return API.postRequest(API.apiUrls.vendors.vendorLeads.getAllLeads, values);
}

export function getVendorsSingleLeadCall(values) {
    return API.postRequest(API.apiUrls.vendors.vendorLeads.getLead, values);
}

export function getVendorsSingleLeadActivityCall(values) {
    return API.postRequest(API.apiUrls.vendors.vendorLeads.getLeadActivity, values);
}

export function addVendorsSingleLeadCall(values) {
    return API.postRequest(API.apiUrls.vendors.vendorLeads.addLead, values);
}

export function updateVendorsSingleLeadCall(values) {
    return API.putRequest(API.apiUrls.vendors.vendorLeads.updateLead, values);
}

export function deleteVendorsSingleLeadCall(values) {
    return API.putRequest(API.apiUrls.vendors.vendorLeads.deleteLead, values);
}

// vendors  leads calls ends