import React, { Suspense, lazy, useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../_metronic/layout";
import { useSelector } from "react-redux";
import { FaRegTimesCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { setShowMenu } from "../store/showMenu/actions";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallbackComponent from "./pages/Components/ErrorBoundaryFallbackComponent/ErrorFallbackComponent";
import ContactUs from "./pages/userPages/footerPages/ContactUs";
import VendorForumSingle from "./pages/userPages/VendorForumSingle";
import VendorSingleLead  from "./pages/userPages/VendorSingleLead";
import VendorActivityLead from "./pages/userPages/VendorActivityLead";


const Dashboard = lazy(() =>
  import("./pages/userPages/Dashboard")
);
const Events = lazy(() =>
import("./pages/userPages/EventsPages")
);

const Inquiries = lazy(() =>
  import("./pages/userPages/Inquiries")
);
const AsideMenu = lazy(() =>
  import("./pages/Components/AsideMenu")
);
const Settings = lazy(() =>
  import("./pages/userPages/Settings")
);
const Subscription = lazy(() =>
  import("./pages/userPages/Subscription")
);
const VendorForum = lazy(() =>
  import("./pages/userPages/VendorForum")
);
const GuestList = lazy(() =>
import("./pages/userPages/planning/GuestList")
);
const WeddingPage = lazy(() =>
  import("./pages/userPages/wedding/WeddingPage")
);
const WeddingRituals = lazy(() =>
import("./pages/userPages/wedding/WeddingRituals")
);
const PlanningMain = lazy(() =>
  import("./pages/userPages/planning/PlanningMain")
);
const SeatingChart = lazy(() =>
import("./pages/userPages/planning/SeatingChart")
);
const BudgetTool = lazy(() =>
  import("./pages/userPages/planning/BudgetTool")
);
const VendorProfile = lazy(() =>
import("./pages/userPages/wedding/VendorProfile")
);
const EngagementPage = lazy(() =>
  import("./pages/userPages/engagement/EngagementPage")
);
const ColorInspiration = lazy(() =>
import("./pages/userPages/planning/ColorInspiration")
);
const HashtagGenerator = lazy(() =>
  import("./pages/userPages/planning/HashtagGenerator")
);
const AdvertiseWithUs = lazy(() =>
import("./pages/userPages/footerPages/AdvertiseWithUs")
);
const AboutUs = lazy(() =>
  import("./pages/userPages/footerPages/AboutUs")
);
const FAQ = lazy(() =>
import("./pages/userPages/footerPages/FAQ")
);
const PrivacyPolicy = lazy(() =>
import("./pages/userPages/footerPages/privacyPolicy")
);
const TermsAndConditions = lazy(() =>
import("./pages/userPages/footerPages/TermsAndConditions")
);
const VendorSupport = lazy(() =>
import("./pages/userPages/footerPages/VendorSupport")
);
const HoneymoonPage = lazy(() =>
import("./pages/userPages/honeymoon/HoneymoonPage")
);
const HoneymoonSections = lazy(() =>
import("./pages/userPages/honeymoon/HoneymoonSections")
);
const InspoBlog = lazy(() =>
import("./pages/userPages/inspo/InspoBlog")
);
const BlogDetail = lazy(() =>
import("./pages/userPages/inspo/BlogDetail")
);
const CheckList = lazy(() =>
import("./pages/userPages/planning/CheckList")
);
const Messages = lazy(() =>
import("./pages/userPages/Messages")
);
const Sweepstakes = lazy(() =>
import("./pages/userPages/sweepstakes/SweepstakesForm")
);
const WebsiteCatalogMain = lazy(() =>
import("./pages/userPages/website/WebsiteCatalogMain")
);
const CreateWebsiteAllDesigns = lazy(() =>
import("./pages/userPages/website/createWebsite/CreateWebsiteAllDesigns")
);
const SelectedTheme = lazy(() =>
import("./pages/userPages/website/createWebsite/SelectedTheme")
);

const VendorLeads = lazy(() =>
  import("./pages/userPages/VendorLeads")
  );
const VendorList = lazy(() =>
import("./pages/userPages/VendorList")
);
const Registry = lazy(() =>
import("./pages/userPages/Registry")
);
const Website = lazy(() =>
import("./pages/userPages/Website")
);
const SiteHomePage = lazy(() =>
import("./pages/userPages/website/createWebsite/SiteHomePage")
);
const SiteSchedulePage = lazy(() =>
import("./pages/userPages/website/createWebsite/SiteSchedulePage")
);
const SiteTravelPage = lazy(() =>
import("./pages/userPages/website/createWebsite/SiteTravelPage")
);
const SiteWeddingPartyPage = lazy(() =>
import("./pages/userPages/website/createWebsite/SiteWeddingPartyPage")
);
const SiteThingsToDoPage = lazy(() =>
import("./pages/userPages/website/createWebsite/SiteThingsToDoPage")
);
const SiteFAQsPage = lazy(() =>
import("./pages/userPages/website/createWebsite/SiteFAQsPage")
);
const Reviews = lazy(() =>
import("./pages/userPages/Reviews")
);
const SiteUsPage = lazy(() =>
import("./pages/userPages/website/createWebsite/SiteUsPage")
);
const SiteRsvpPage = lazy(() =>
import("./pages/userPages/website/createWebsite/SiteRsvpPage")
);
const SiteHomePreviewPage = lazy(() =>
import("./pages/userPages/website/createWebsite/previewPages/SiteHomePreviewPage")
);
const SiteSchedulePreviewPage = lazy(() =>
import("./pages/userPages/website/createWebsite/previewPages/SiteSchedulePreviewPage")
);
const SiteTravelPreviewPage = lazy(() =>
import("./pages/userPages/website/createWebsite/previewPages/SiteTravelPreviewPage")
);
const SiteFAQsPreviewPage = lazy(() =>
import("./pages/userPages/website/createWebsite/previewPages/SiteFAQsPreviewPage")
);
const SiteUsPreviewPage = lazy(() =>
import("./pages/userPages/website/createWebsite/previewPages/SiteUsPreviewPage")
);
const SiteThingsToDoPreviewPage = lazy(() =>
import("./pages/userPages/website/createWebsite/previewPages/SiteThingsToDoPreviewPage")
);
const SiteRsvpPreviewPage = lazy(() =>
import("./pages/userPages/website/createWebsite/previewPages/SiteRsvpPreviewPage")
);
const SiteWeddingPartyPreviewPage = lazy(() =>
import("./pages/userPages/website/createWebsite/previewPages/SiteWeddingPartyPreviewPage")
);
const SweepstakesMainPage = lazy(() =>
import("./pages/userPages/sweepstakes/SweepstakesMainPage")
);
const SweepstakesSuccess = lazy(() =>
import("./pages/userPages/sweepstakes/SweepstakesSuccess")
);
const Paymentslist = lazy(() =>
import("./pages/userPages/vendorpayments/Paymentslist")
);
const SeatingChartGuestQuest = lazy(() =>
import("./pages/userPages/planning/SeatingChartGuestQuest")
);
const ComingSoon = lazy(() =>
import("./pages/Components/ComingSoon")
);
const NotFound404Page = lazy(() =>
import("./pages/404/NotFound404Page")
);
const Site404Page = lazy(() =>
import("./pages/userPages/website/createWebsite/previewPages/Site404Page")
);
const RegistryMain = lazy(() =>
import("./pages/userPages/registry/RegistryMain")
);
const SiteRegistryPage = lazy(() =>
import("./pages/userPages/website/createWebsite/SiteRegistryPage")
);
const SiteRegistryPreviewPage = lazy(() =>
import("./pages/userPages/website/createWebsite/previewPages/SiteRegistryPreviewPage")
);
const VendorPurchaseSubscription= lazy(() =>
import("././modules/Auth/userAuthPages/VendorPurchaseSubscription")
);

const SubscriptionPlans  = lazy(() =>
import("./pages/SubscriptionPlans")
);

const SubscriptionViewPlan  = lazy(() =>
import("./pages/SubscriptionViewPlan")
);

const HoneymoonQuizPage = lazy(() => 
import("./pages/userPages/honeymoon/honeymoonQuiz/HoneymoonQuizPage")
);

const HoneymoonQuizResult = lazy(() =>
import("./pages/userPages/honeymoon/honeymoonQuiz/HoneymoonQuizResult")
);

function RoutesMain() {

  const [profileImage, setProfileImage] = useState(null);
  //   const [show_menu, setshow_menu] = useState(false);
  const dispatch = useDispatch();
  const show_menu = useSelector((store) => store.ShowMenu.show_menu);
  const loggedInUser = useSelector((store) => store.UserLoggedIn.loggedInUser);

  

  useEffect(() => {
    if (
      loggedInUser?.profile_image !== null ||
      loggedInUser?.profile_image !== undefined ||
      loggedInUser?.profile_image !== ""
    ) {
      setProfileImage(loggedInUser?.profile_image);
    }
  }, [loggedInUser?.profile_image]);

  const handleShowMenu = () => {
    dispatch(setShowMenu(true));
    setSidebarBtn(true);
  };

  const handleHideMenu = () => {
    dispatch(setShowMenu(false));
  };

  const [sidebarBtn, setSidebarBtn] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < "992") {
        setSidebarBtn(false);
      } else {
        setSidebarBtn(false);
      }
      //console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
    }

    window.addEventListener("resize", handleResize);
    
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const errorHandler = (error, errorInfo) => {
    //console.log({error, errorInfo});
  }

 

  return (
    
    <>
  
      <div className="">
      <ErrorBoundary FallbackComponent={ErrorFallbackComponent} onError={errorHandler}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          <Route path="/" component={WeddingPage} exact />
          <Route path="/guestlist" component={GuestList} exact />
          <Route path="/invite-guests/:eventId" component={GuestList} exact />
          <Route path="/wedding" component={WeddingPage} exact />
          <Route
            path="/wedding/:categoryName"
            component={WeddingRituals}
            exact
          />

          <Route
            path="/vendor-profile/:vendorId"
            component={VendorProfile}
            exact
          />

          <Route path="/engagement" component={EngagementPage} exact />
          <Route
            path="/engagement/:categoryName"
            component={EngagementPage}
            exact
          />

          <Route path="/honeymoon" component={HoneymoonPage} exact />
          <Route path="/honeymoon-quiz" component={HoneymoonQuizPage} exact />
          <Route path="/honeymoon-result/:answer" component={HoneymoonQuizResult} exact />
          <Route path="/registry-main" component={RegistryMain} exact />
          <Route
            path="/honeymoon/:categoryName"
            component={HoneymoonSections}
            exact
          />
         
          <Route
            path="/events"
            component={Events}
            exact
          />
          

          <Route path="/advertise-with-us" component={AdvertiseWithUs} exact />
          <Route path="/subscription-plans" component={SubscriptionPlans} exact />
          <Route path="/subscription-plan/:planId" component={SubscriptionViewPlan} exact />
          <Route path="/about-us" component={AboutUs} exact />
          <Route path="/contact-us" component={ContactUs} exact />
          <Route path="/faq" component={FAQ} exact />
          <Route path="/privacy-policy" component={PrivacyPolicy} exact />
          <Route
            path="/terms-and-conditions"
            component={TermsAndConditions}
            exact
          />
          <Route path="/vendor-support" component={VendorSupport} exact />

          <Route path="/planning" component={PlanningMain} exact />
          <Route path="/seating-chart" component={SeatingChart} exact />
          <Route
            path="/seating-chart/guest-quest"
            component={SeatingChartGuestQuest}
            exact
          />
          <Route path="/color-inspiration" component={ColorInspiration} exact />
          <Route path="/hashtag-generator" component={HashtagGenerator} exact />
          <Route path="/budget-tool" component={BudgetTool} exact />
          <Route path="/checklist" component={CheckList} exact />

          <Route path="/inspo-blog" component={InspoBlog} exact />
          <Route path="/inspo-blog/:slug" component={BlogDetail} exact />

          <Route
            path="/inspo-blog/category/:categoryName"
            component={InspoBlog}
            exact
          />

          <Route path="/sweepstakes" component={SweepstakesMainPage} exact />
          <Route path="/sweepstakes/form" component={Sweepstakes} exact />
          <Route
            path="/sweepstakes/success"
            component={SweepstakesSuccess}
            exact
          />

          <Route
            path="/website-catalog-main"
            component={WebsiteCatalogMain}
            exact
          />
          <Route
            path="/create-website/all-designs"
            component={CreateWebsiteAllDesigns}
            exact
          />
          <Route
            path="/create-website/selected-theme/:themeId"
            component={SelectedTheme}
            exact
          />
          <Route path="/website" component={Website} exact />
          <Route
            path="/website/:siteSlug/home"
            component={SiteHomePage}
            exact
          />
          <Route
            path="/website/:siteSlug/schedule"
            component={SiteSchedulePage}
            exact
          />
          <Route
            path="/website/:siteSlug/travel"
            component={SiteTravelPage}
            exact
          />
          <Route
            path="/website/:siteSlug/registry"
            component={SiteRegistryPage}
            exact
          />
          <Route
            path="/website/:siteSlug/wedding-party"
            component={SiteWeddingPartyPage}
            exact
          />
          <Route
            path="/website/:siteSlug/things-to-do"
            component={SiteThingsToDoPage}
            exact
          />
          <Route
            path="/website/:siteSlug/faqs"
            component={SiteFAQsPage}
            exact
          />
          <Route path="/website/:siteSlug/us" component={SiteUsPage} exact />
          <Route
            path="/website/:siteSlug/rsvp"
            component={SiteRsvpPage}
            exact
          />

          <Route
            path="/website/preview/:siteSlug/home"
            component={SiteHomePreviewPage}
            exact
          />
          <Route
            path="/website/preview/:siteSlug/schedule"
            component={SiteSchedulePreviewPage}
            exact
          />
          <Route
            path="/website/preview/:siteSlug/travel"
            component={SiteTravelPreviewPage}
            exact
          />
          <Route
            path="/website/preview/:siteSlug/registry"
            component={SiteRegistryPreviewPage}
            exact
          />
          <Route
            path="/website/preview/:siteSlug/wedding-party"
            component={SiteWeddingPartyPreviewPage}
            exact
          />
          <Route
            path="/website/preview/:siteSlug/things-to-do"
            component={SiteThingsToDoPreviewPage}
            exact
          />
          <Route
            path="/website/preview/:siteSlug/faqs"
            component={SiteFAQsPreviewPage}
            exact
          />
          <Route
            path="/website/preview/:siteSlug/us"
            component={SiteUsPreviewPage}
            exact
          />
          <Route
            path="/website/preview/:siteSlug/rsvp"
            component={SiteRsvpPreviewPage}
            exact
          />
          <Route
            path="/website/preview/:siteSlug/404"
            component={Site404Page}
            exact
          />

          <Route path="/coming-soon" component={ComingSoon} exact />

          <Route path="/404" component={NotFound404Page} exact />

          <div className="w-100 d-flex px-25 asideMenuAndContentAreaContainer">
            <div
              className={`vh-100 py-5 asideMainMenu ${show_menu == true &&
                "sidebar-show"}`}
            >
              {sidebarBtn && (
                <div
                  className={`hide-menu-wrapper ${show_menu == false &&
                    "d-none"}`}
                >
                  <button
                    className="btn btn-primary btn-menu-hide-show btn-sm"
                    onClick={handleHideMenu}
                  >
                    <FaRegTimesCircle />
                    {/* <i className="fa fa-times"></i> */}
                  </button>
                </div>
              )}
              {/*
              <div
                className={`show-menu-wrapper ${show_menu == true && "d-none"}`}
              >
                <p className="menu-show" onClick={handleShowMenu}>
                  <span>Menu</span>
                </p>
              </div>*/}
              <div className="menu-wrapper-hide-show">
                <div>
                  <img
                    src={
                      profileImage === null ||
                      profileImage === undefined ||
                      profileImage === ""
                        ? "/media/pictures/default-avatar.svg"
                        : profileImage
                    }
                    className="rounded-circle object-fit-cover"
                    width={"150px"}
                    height={"150px"}
                  />
                </div>
                <div className="mt-3 text_muted">
                  <p className="mb-0 font_size_13 text-uppercase text-break">
                    {loggedInUser?.name}
                  </p>
                  <p className="font_size_13 text-uppercase text-break">
                    {loggedInUser?.email}
                  </p>
                </div>
              </div>
              <div className="mt-3 menu-wrapper-hide-show">
                <AsideMenu />
              </div>
            </div>
            <div className="w-100 content_area">
              <Route path="/dashboard" component={Dashboard} exact />
              <Route path="/inquiries" component={Inquiries} exact />
              <Route path="/settings" component={Settings} exact />
              <Route path="/messages" component={Messages} exact />
              <Route path="/subscription" component={Subscription} exact />
              <Route path="/vendor-forum" component={VendorForum} exact />
               <Route
              path="/vendor-forum/:forumId"
              component={VendorForumSingle}
              exact
              /> 
              <Route
              path="/vendor-lead/:leadId"
              component={VendorSingleLead}
              exact
              /> 
              <Route
              path="/vendor-lead-activity/:leadId"
              component={VendorActivityLead}
              exact
              />  
              <Route path="/vendor-leads" component={VendorLeads} exact />
              <Route path="/vendor-list" component={VendorList} exact />
              <Route path="/registry" component={Registry} exact />
              <Route path="/reviews" component={Reviews} exact />
              <Route path="/payments" component={Paymentslist} exact />
              <Route path="/vendor-purchase-subscription/:vendorId" component={VendorPurchaseSubscription} exact />
    

              <Redirect from="/" exact={true} to="/dashboard" />
              <Redirect to="/dashboard" />
            </div>
          </div>
        </Switch>
        </Suspense>
        </ErrorBoundary>
      </div>
    </>
  );
}

export default RoutesMain;
