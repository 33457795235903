import React from 'react'

function ComingSoon() {
  return (
    <div className='w-100 d-flex justify-content-center align-items-center p-5'>
      <div className='w-100 d-flex flex-column justify-content-center align-items-center p-5' style={{ height: '550px', backgroundColor: '#FAF8F8'}}>
        {/* <img src='/media/pictures/coming-soon-no-widget.png' className='img-fluid'/> */}
        <img src={"/media/svgs/logo_3.svg"} />
        <div className='vertical-line my-3'></div>
        <h1 className='coming-soon-title text-center text-secondary'>Coming Soon!</h1>
        <h6 className='text-uppercase text-center text-secondary'>WE ARE CURRENTLY UNDER CONSTRUCTION.</h6>
        <span className='text-uppercase text-center text-secondary mt-3'>CONTACT US TO LEARN MORE:</span>
        <a href='mailto:Support@Weddingmatch.com' className='text-dark font-weight-bold text-center'>Support@Weddingmatch.com</a>
      </div>
    </div>
  )
}

export default ComingSoon